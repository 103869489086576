import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // private _BaseUrl = "http://13.234.204.118:5000/AdminApp"
  // private _BaseUrl = "http://3.109.1.110:8486"
  private _BaseUrl = "https://un.metalsart.in/AdminApp"
  //private URL="http://127.0.0.1:8000/AdminApp"
  token: any

  constructor(private _http: HttpClient) {
    this.token = localStorage.getItem('isLoogedIn')
    // console.log(this.token)

  }



  // '1' *******Login /

  isLogin(data: any) {

    return this._http.post(`${this._BaseUrl}/LoginAPI/`, data)
      .pipe(catchError(this.errorHandler))

  }

   //3 SuperAdminAPI Active List Api
   getExamTypeByIdList(courseId:number) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ExamListByCourseIdAPI/?courseId=`+ courseId,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


   //3 SuperAdminAPI Active List Api
   getSubjectByIdList(courseId:number) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SubjectByCourseIdAPI/?courseId=`+ courseId,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveStudentList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/StudentListAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //2  SuperAdminAPI Add  API
  superAdminAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SuperAdminAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveSuperAdminList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveSuperAdminList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteSuperAdmin(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SuperAdminDeleteAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  superAdminData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateSuperAdmin(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SuperAdminAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }


  //2  SuperAdminAPI Add  API
  teamAdminAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TeamAdminAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveTeamAdminList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TeamAdminAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveTeamAdminList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TeamAdminAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteTeamAdmin(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/TeamAdminAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  teamAdminData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/TeamAdminAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateTeamAdmin(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/TeamAdminAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }







  //2  SuperAdminAPI Add  API
  courseAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/CourseAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveCourseList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/CourseAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveCourseList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/CourseAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteCourse(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/CourseAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  courseData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/CourseAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateCourse(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/CourseAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }


  



  //2  SuperAdminAPI Add  API
  subjectAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SubjectAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveSubjectList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SubjectActiveAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveSubjectList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SubjectInActiveAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteSubject(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SubjectAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  subjectData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SubjectAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateSubject(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SubjectAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }






  //2  SuperAdminAPI Add  API
  adminAdvertiseAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SuperAdminAdvertiseAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveAdminAdvertiseList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAdvertiseAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveAdminAdvertiseList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAdvertiseAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteAdminAdvertise(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SuperAdminAdvertiseAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  adminAdvertisetData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SuperAdminAdvertiseAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateAdminAdvertise(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SuperAdminAdvertiseAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }



  //2  SuperAdminAPI Add  API
  examTypeAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ExamTypeAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveExamTypeList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ExamTypeAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveExamTypeList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ExamTypeAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteExamType(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/ExamTypeAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  ExamTypeData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ExamTypeAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateExamType(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ExamTypeAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }


  //2  SuperAdminAPI Add  API
  SyllabusAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SyllabusAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveSyllabusList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SyllabusGetAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveSyllabusList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SyllabusGetInActiveAPI/`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteSyllabus(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/SyllabusAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  SyllabusData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/SyllabusAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateSyllabus(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/SyllabusAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }


  //2  SuperAdminAPI Add  API
  ExamPaperAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ExamPaperAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveExamPaperList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ExamPaperAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveExamPaperList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ExamPaperAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteExamPaper(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/ExamPaperAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  ExamPaperData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ExamPaperAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateExamPaper(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ExamPaperAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }














  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


  //2  SuperAdminAPI Add  API
  bookAdd(data: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ImagePDFUploadAPI/`, data, {
      headers: opt
    })
      .pipe(catchError(this.errorHandler))
  }


  //3 SuperAdminAPI Active List Api
  getActiveBookList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ImagePDFUploadAPI/?status=1`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //4 Get Inactive List SuperAdminAPI
  getInActiveBookList() {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ImagePDFUploadAPI/?status=0`,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }

  //5 **** SuperAdminAPI Delete APi

  deleteBook(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.delete(`${this._BaseUrl}/ImagePDFUploadAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))
  }


  //6 **** SuperAdminAPI Data Api
  bookData(id: any) {
    const opt = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.get(`${this._BaseUrl}/ImagePDFUploadAPI/?id=` + id,
      {
        headers: opt
      })
      .pipe(catchError(this.errorHandler))

  }




  //7 ****SuperAdminAPI Update Api
  updateBook(data: any) {
    const optHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    });
    return this._http.post(`${this._BaseUrl}/ImagePDFUploadAPI/`, data,
      {
        headers: optHeader
      })
      .pipe(catchError(this.errorHandler))

  }
}
